<template>
  <body-with-header title="لیست هزینه ها">
    <template #header>
      <CRow align-vertical="center">
        <CCol md="4">
          <multiselect
            v-model="farms"
            :options="farmsSource"
            track-by="value"
            label="text"
            deselect-label=""
            placeholder="نمایش تمام زمین ها"
            selectLabel=""
            @input="onChangeFarms"
            :searchable="true"
            :multiple="true"
            :maxHeight="200"
          ></multiselect>

          <multiselect
            v-model="costTypes"
            :options="costTypesSource"
            track-by="value"
            label="text"
            deselect-label=""
            placeholder="نمایش تمام نوع هزینه ها"
            selectLabel=""
            @input="onChangecostTypes"
            :searchable="true"
            :multiple="true"
            :maxHeight="200"
          ></multiselect>
        </CCol>
        <CCol md="6">
          <div class="priceBox_1" v-if="sum">
            <label class="captionCost">مجموع تمام هزینه ها</label>
            <label class="valueCost">{{ formatPrice(sum) }} ریال</label>
          </div>
          <div class="priceBox_2" v-if="costTypeSum">
            <label class="captionCost">مجموع هزینه های انتخاب شده</label>
            <label class="valueCost"
            >{{ formatPrice(costTypeSum) }} ریال</label
            >
          </div>
        </CCol>
        <CCol>
          <CButton
            block
            color="primary"
            @click="showModal(0)"
          >
            ثبت هزینه جدید
          </CButton>
        </CCol>
      </CRow>
    </template>

    <vue-element-loading
      :active="formLoading"
      :text="formLoadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <ag-grid-vue
      style="height: 80vh"
      class="ag-theme-alpine"
      :headerHeight="30"
      :rowHeight="40"
      :columnHoverHighlight="true"
      :columnDefs="fields"
      :defaultColDef="defaultColDef"
      @grid-ready="fetchData"
      :rowData="rowData"
    >
    </ag-grid-vue>
    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      modal-class="drawer"
      in-class="animate__fadeInRight"
      out-class="animate__fadeOutRight"
    >
      <CForm>
        <CRow class="gap-20">
          <vue-element-loading
            :active="loading"
            :text="loadingText"
            spinner="bar-fade-scale"
            color="var(--secondary)"
          />
          <CCol md="12">
            <span class="ml-1">زمین</span>
            <v-select
              dir="rtl"
              v-model="selectedfarmerFarm"
              :options="farmerFarmSource"
              placeholder="زمین را انتخاب کنید"
            ></v-select>
          </CCol>
          <CCol md="12">
            <span class="ml-1">نوع هزینه</span>
            <v-select
              dir="rtl"
              v-model="selectedCostType"
              :options="costTypeSource"
              placeholder="نوع هزینه را انتخاب کنید"
            ></v-select>
          </CCol>
          <CCol md="12">
            <span class="ml-1">واحد هزینه</span>
            <v-select
              dir="rtl"
              v-model="selectedUnitType"
              :options="unitTypeSource"
              placeholder="واحد هزینه را انتخاب کنید"
            ></v-select>
          </CCol>
          <CCol md="12">
            <small class="ml-1 inputLabel">تعداد یا مقدار</small>
            <CInput type="text" class="inputValue" v-model="quantity"/>
          </CCol>
          <CCol md="12">
            <small class="ml-1 inputLabel">کل هزینه پرداختی (ریال)</small>
            <CInput type="text" class="inputValue" v-model="totalCost"/>
          </CCol>

          <CCol md="12">
            <small class="ml-1 inputLabel">توضیحات</small>
            <CInput type="text" class="inputValue" v-model="description"/>
          </CCol>
          <CCol md="12">
            <CRow class="w-100" align-horizontal="end">
                <CButton
                  color="primary"
                  class="center-g-5 text-center"
                  block
                  @click="submit()"
                >
                  <CIcon name="cil-check" size="lg"/>
                  <span>ثبت</span>
                </CButton>
            </CRow>
          </CCol>
        </CRow>
      </CForm>
    </VueModal>
  </body-with-header>
</template>

<script>
import {mapActions} from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Multiselect from "vue-multiselect";
import CostType from "../../store/data/enum/costType.json";
import UnitType from "../../store/data/enum/unitType.json";
import BodyWithHeader from "../../components/bodyWithHeader.vue";

export default {
  name: "costForm",
  components: {
    BodyWithHeader,
    vSelect,
    Multiselect,
  },
  data() {
    return {
      fields: [
        {
          headerName: "عملیات",
          field: "icon",
          headerClass: "fixed-size-header",
          cellRenderer: (params) => {
            return `
            <button  onClick="showModal(${params.data.id})" class="editCellBtn"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
            <button  onClick="deleteCost(${params.data.id})" class="deleteCellBtn"><i class="fa fa-trash" aria-hidden="true"></i></button>`;
          },
        },
        {
          field: "totalCostViewer",
          headerName: "هزینه پرداختی (ریال)",
        },
        {
          field: "quantity",
          headerName: "تعداد",
        },
        {
          field: "unit",
          headerName: "واحد",
        },
        {
          field: "typeName",
          headerName: "نوع هزینه",
        },
        {
          field: "farmName",
          headerName: "نام زمین",
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      rowData: null,
      modalTitle: "",
      modelShowState: false,

      formLoading: false,
      formLoadingText: "",
      loading: false,
      loadingText: "",

      costTypeSum: null,
      sum: null,
      farms: [],
      farmsSource: [],
      costTypesSource: [],
      costTypes: [],

      //Input Data
      costTypeSource: CostType,
      selectedCostType: "",
      unitTypeSource: UnitType,
      selectedUnitType: "",
      farmerFarmSource: [],
      selectedfarmerFarm: "",

      id: 0,
      description: "",
      totalCost: "",
      quantity: "",
    };
  },
  watch: {
    totalCost: {
      handler: function (val) {
        this.totalCost = this.formatPrice(val);
      },
    },
  },
  methods: {
    ...mapActions("farmCost", [
      "GetAll",
      "Filter",
      "GetById",
      "Insert",
      "Edit",
      "Delete",
    ]),
    ...mapActions("farm", ["GetsTextValue"]),

    formatPrice(Number) {
      Number += "";
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      let x = Number.split(".");
      let y = x[0];
      let z = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(y)) y = y.replace(rgx, "$1" + "," + "$2");
      return y + z;
    },
    async onChangeFarms(value) {
      this.farms = value;
      if (value.indexOf("Reset me!") !== -1) this.farms = [];
      await this.fetchData();
    },
    async onChangecostTypes(value) {
      this.costTypes = value;
      if (value.indexOf("Reset me!") !== -1) this.costTypes = [];
      await this.fetchData();
    },

    async fetchData(params) {
      this.sum = null;
      this.costTypeSum = null;
      if (this.farms.length > 0 || this.costTypes.length > 0) {
        let farmsRequest = [];
        let costTypesRequest = [];

        if (this.farms.length > 0) {
          this.farms.map((item) => {
            farmsRequest.push(item.value);
          });
        }
        if (this.costTypes.length > 0) {
          this.costTypes.map((item) => {
            costTypesRequest.push(item.value);
          });
        }

        let result = await this.Filter({
          farmIds: farmsRequest,
          costTypes: costTypesRequest,
          pageNumber: 0,
          pageSize: 0,
        });

        if (result.getByFilterFarmCostsModel.succeeded) {
          let costData = result.getByFilterFarmCostsModel.data.map(
            (item, index) => {
              this.costTypeSum += item.totalCost;
              return {...item, index};
            }
          );
          this.sum = result.costTotal;
          if (params) {
            params.api.setRowData(costData);
            params.api.sizeColumnsToFit();
          } else {
            this.rowData = costData;
          }
        } else {
          this.$notify({
            title: "خطا رخ داده است",
            text: "امکان دریافت لیست نیست <br /><br />",
            type: "error",
          });
        }
      } else {
        var result = await this.GetAll({pageNumber: 0, pageSize: 0});
        if (result) {
          let costData = result.map((item, index) => {
            this.sum += item.totalCost;
            return {...item, index};
          });
          if (params) {
            params.api.setRowData(costData);
            params.api.sizeColumnsToFit();
          } else {
            this.rowData = costData;
          }
        } else {
          this.$notify({
            title: "خطا رخ داده است",
            text: "امکان دریافت لیست نیست <br /><br />",
            type: "error",
          });
        }
      }
    },
    async submit() {
      this.loading = true;
      let cost = {
        id: this.id,
        description: this.description,
        totalCost: this.totalCost,
        quantity: this.quantity,
        farmId: this.selectedfarmerFarm.id,
        type: this.selectedCostType.value,
        unit: this.selectedUnitType.value,
      };
      var result =
        this.id == 0
          ? await this.Insert({farmCost: cost})
          : await this.Edit({farmCost: cost});
      this.loading = false;
      if (result) {
        this.$notify({
          title: "پیام سیستم",
          text:
            this.id == 0
              ? "ثبت هزینه انجام شد   <br /><br />"
              : "اطلاعات هزینه ویرایش شد  <br /><br />",
          type: "success",
        });
        await this.fetchData();
        this.modelShowState = false;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا رخ داده است <br /><br />",
          type: "error",
        });
        this.modelShowState = false;
      }
    },
    async getFarmerFarm() {
      this.farmerFarmSource = [];
      var result = await this.GetsTextValue({});
      result.filter((item) => {
        let farm = {
          id: item.value,
          label: item.text,
        };
        this.farmerFarmSource.push(farm);
      });
    },
    async showModal(id) {
      await this.getFarmerFarm();
      if (id == 0) {
        this.id = 0;
        this.description = "";
        this.totalCost = "";
        this.quantity = "";
        this.selectedCostType = "";
        this.selectedUnitType = "";
        this.selectedfarmerFarm = "";

        this.modelShowState = true;
        this.modalTitle = "ثبت هزینه جدید";
      } else {
        let result = await this.GetById({id: id});
        if (result) {
          this.id = result.id;
          this.description = result.description;
          this.totalCost = result.totalCost;
          this.quantity = result.quantity;
          this.selectedCostType = this.costTypeSource.filter((item) => {
            return item.value == result.type;
          })[0];
          this.selectedUnitType = this.unitTypeSource.filter((item) => {
            return item.value == result.unit;
          })[0];
          this.selectedfarmerFarm = this.farmerFarmSource.filter((item) => {
            return item.id == result.farmId;
          })[0];
          this.modelShowState = true;
          this.modalTitle = "ویرایش اطلاعات هزینه";
        }
      }
    },
    async deleteCost(id) {
      this.$confirm({
        message: `هزینه مورد نظر حذف شود؟`,
        button: {
          no: "خیر",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.formLoadingText = "در حال حذف هزینه...";
            this.formLoading = true;
            let result = await this.Delete({id: id});
            this.formLoading = false;
            if (result) {
              await this.fetchData();
              this.$notify({
                title: "پیام سیستم",
                text: "هزینه مورد نظر حذف شد <br /><br />",
                type: "success",
              });
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: "امکان حذف این سطر وجود ندارد <br /><br />",
                type: "error",
              });
            }
          }
        },
      });
    },

    async getFarmsList() {
      var result = await this.GetsTextValue({});
      result.filter((item) => {
        let farm = {
          text: item.text,
          value: item.value,
        };
        this.farmsSource.push(farm);
      });
    },
    getCostTypesSource() {
      CostType.filter((item) => {
        if (item.label) {
          let type = {
            text: item.label,
            value: item.value,
          };
          this.costTypesSource.push(type);
        }
      });
    },
  },
  created() {
    window.deleteCost = this.deleteCost;
    window.showModal = this.showModal;
  },
  mounted() {
    this.getFarmsList();
    this.getCostTypesSource();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

.form-group{
  margin: 0!important;
}
.invalid,
.is-invalid {
  border-color: #e55353;
  background-image: url("/img/banner.png");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.multiselect__tags {
  padding: 2px 8px 0 40px;
  height: 30px;
  min-height: 30px;
}

[dir="rtl"] .multiselect__tags {
  padding: 2px 8px 0 40px;
  height: 30px;
  min-height: 30px;
}

.selectValue {
  height: 30px;
  margin: 0px;
  float: right;
  clear: both;
  width: 100%;
}
.main-card{
  overflow: visible;
}
.card{
  height: 100%;
}
</style>
<style scoped>


.inputLabel {
  font-size: 13px;
  margin-top: 6px;
}

.captionCost {
  text-align: center;
  background-color: var(--secondary);
  color: #fff;
  padding: 5px;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  margin: 0;
  border: 1px solid var(--secondary);
}

.valueCost {
  text-align: center;
  border: 1px solid var(--secondary);
  padding: 5px;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  margin: 0;
}
</style>
